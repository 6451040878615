import axios from 'axios'
import { getAuth } from 'firebase/auth'

/**
 * Instantiate Axios instances
 */
export const serverApi = axios.create({
  baseURL: import.meta.env.PUBLIC_API_ROOT,
  timeout: 30000,
})
export const billingApi = serverApi.create({ baseURL: import.meta.env.PUBLIC_FUNCTION_BILLING })
export const userApi = serverApi.create({ baseURL: import.meta.env.PUBLIC_FUNCTION_USER })
export const teamApi = serverApi.create({ baseURL: import.meta.env.PUBLIC_FUNCTION_TEAM })

/**
 * Add request interceptor to attach the firebase auth token and browser timezone
 */
async function useAuth (config) {
  const user = getAuth().currentUser
  if (user) config.headers['Authorization'] = await user.getIdToken()
  return config
}
serverApi.interceptors.request.use(useAuth)
billingApi.interceptors.request.use(useAuth)
userApi.interceptors.request.use(useAuth)
teamApi.interceptors.request.use(useAuth)

/**
 * Measure ping
 */
// instance.interceptors.request.use(config => {
//   config.meta = config.meta || {}
//   config.meta.requestStartedAt = Date.now()
//   return config
// })

// instance.interceptors.response.use(res => {
//   res.config.meta.ping = Date.now() - res.config.meta.requestStartedAt
//   console.info('Ping', Date.now() - res.config.meta.requestStartedAt)
//   return res
// })

export default serverApi
