import { defineStore } from 'pinia'
import { electronConstants } from '@stagetimerio/shared'
import axios from '../axios.js'
import Cookies from 'js-cookie'
import _keyBy from 'lodash/keyBy'
import _set from 'lodash/set'
import _sortBy from 'lodash/sortBy'
import _isEmpty from 'lodash/isEmpty'

let resolveInitPromise = undefined
let resolveTeamInitPromise = undefined
let userFetcher = null
let teamsFetcher = null

export const useUser = defineStore('user', {
  state: () => ({
    initPromise: new Promise(r => resolveInitPromise = r),
    teamInitPromise: new Promise(r => resolveTeamInitPromise = r),
    user: null,
    teamsMap: {},
    teamId: null,
    loading: true,
    error: null,
    justPurchased: false,
  }),
  getters: {
    init: state => state.initPromise,
    teamInit: state => state.teamInitPromise,
    get: state => state.user,
    uid: state => state.user?.uid,
    admin: state => Boolean(state.user?.customClaims?.admin),
    photoUrl: state => state.user?.photoURL || 'https://stagetimer.io/assets/default-avatar.png',
    email: state => state.user?.email,
    emailVerified: state => state.user?.emailVerified,
    displayName: state => state.user?.displayName || state.user?.email,
    provider: state => state.user?.providerData || [],
    anonymous: state => state.user?.isAnonymous,
    authenticated: state => state.user && !state.user.isAnonymous,
    creationTime: state => new Date(state.user?.creationTime),
    teams: state => sortTeams(Object.values(state.teamsMap), state.uid),
    getTeam: state => teamId => state.teamsMap[teamId],
    isTeamMember: state => team => !_isEmpty(team?.members) && (state.uid in team.members),
    getTeamRole: state => team => team && team.members[state.uid]?.role,
    canAccessTeam: state => (team, roles = true) => {
      if (state.admin) return true
      if (roles === true) return state.isTeamMember(team)
      if (typeof roles === 'string') return state.getTeamRole(team) === roles
      if (Array.isArray(roles)) return roles.includes(state.getTeamRole(team))
      return false
    },
    hasFeature: state => (teamId, feature) => state.teamsMap[teamId]?.features?.includes(feature) || false,
  },
  actions: {
    /**
     * Resets the store state, particularly the initPromise, to its initial state.
     */
    reset () {
      this.unsetUser()
      this.initPromise = new Promise(r => resolveInitPromise = r)
      this.teamInitPromise = new Promise(r => resolveTeamInitPromise = r)
    },

    /**
     * Initializes user data upon Electron app initiation.
     * This is typically called with the user ID from the Electron constants.
     */
    async onElectronInit () {
      await this.fetchAndSetUser(electronConstants.uid)
    },

    /**
     * Handles user authentication updates from Firebase.
     * If the user is logged in, it attempts to load user data.
     * If the user is null (not logged in), it resets the store state.
     * @param {Object|null} user - The user object from Firebase auth, or null if not logged in.
     */
    async onFirebaseAuth (user) {
      if (user?.uid) { // Firebase event with user object
        Cookies.set('authenticated', true, { expires: 30, sameSite: 'Lax' })
        await this.fetchAndSetUser(user.uid)
      } else { // Firebase event with null
        Cookies.remove('authenticated')
        return this.unsetUser()
      }
    },

    async fetchAndSetUser (uid) {
      this.error = null
      this.loading = true

      try {
        await Promise.all([
          this.fetchUser(uid),
          this.fetchTeams(uid),
        ])
      } catch (err) {
        this.error = err
        throw err
      } finally {
        this.loading = false
      }
    },

    async fetchUser (uid) {
      if (userFetcher) userFetcher.cancel()
      userFetcher = createFetchWithBackoff(() => axios.get(`/users/${uid}`))
      try {
        const { data: user } = await userFetcher()
        this.user = user
        resolveInitPromise?.(user)
      } catch (err) {
        console.error('[user.js] Failed to fetch user:', err)
        resolveTeamInitPromise?.(null)
      } finally {
        userFetcher = null
      }
    },

    async fetchTeams (uid) {
      if (teamsFetcher) teamsFetcher.cancel()
      teamsFetcher = createFetchWithBackoff(() => axios.get(`/users/${uid}/teams`))
      try {
        const { data: teams } = await teamsFetcher()
        this.teamsMap = _keyBy(teams, 'id')
        resolveTeamInitPromise?.(teams)
      } catch (err) {
        console.error('[user.js] Failed to fetch teams:', err)
        resolveTeamInitPromise?.(null)
      } finally {
        teamsFetcher = null
      }
    },

    cancelFetchUser () {
      if (userFetcher) userFetcher.cancel()
      userFetcher = null
    },

    cancelFetchTeams () {
      if (teamsFetcher) teamsFetcher.cancel()
      teamsFetcher = null
    },

    unsetUser () {
      this.user = null
      this.teamsMap = {}
      this.teamId = null
      this.loading = false
      this.cancelFetchUser()
      this.cancelFetchTeams()
      resolveInitPromise?.(null)
      resolveTeamInitPromise?.(null)
    },

    updateTeam (teamId, patch) {
      _set(this.teamsMap, teamId, patch)
    },
  },
})

function createFetchWithBackoff (fetchFn, maxRetries = 5, baseDelay = 1000) {
  let retryCount = 0
  let cancelRequest = false

  const execute = async () => {
    while (retryCount < maxRetries && !cancelRequest) {
      try {
        return await fetchFn()
      } catch (err) {
        if (err?.response?.status === 404) {
          console.info(`[user.js] Retrying... Attempt ${retryCount + 1}`)
          const delay = baseDelay * Math.pow(2, retryCount)
          await new Promise(resolve => setTimeout(resolve, delay))
          retryCount++
        } else {
          throw err
        }
      }
    }
    if (cancelRequest) return console.info(`[user.js] Request canceled after ${retryCount + 1} retires`)
    throw new Error(`[user.js] Max retries reached after ${retryCount + 1} retires`)
  }

  const cancel = () => {
    cancelRequest = true
  }

  const reset = () => {
    retryCount = 0
    cancelRequest = false
  }

  return Object.assign(execute, { cancel, reset })
}

/**
 * Make sure the user's primary team comes first
 * @param  {object[]} items
 * @param  {string} uid
 * @return {object[]}
 */
function sortTeams (items, uid) {
  return _sortBy(items, item => item.id === uid ? -1 : 0)
}
